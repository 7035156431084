import { Controller } from "@hotwired/stimulus"
import { put } from "@rails/request.js"

// Connects to data-controller="dnd-member"
export default class extends Controller {
  static targets = ["member"];

  dragStart(event) {
    console.log("start")
    event.target.style.opacity = "0.4";
    this.dragSrcEl = event.target;

    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text/html", event.target.innerHTML);
  }

  dragEnter(event) {
    console.log("enter")
    event.target.classList.add("over");

    if (event.preventDefault) {
      event.preventDefault();
    }
    return false;
  }

  dragOver(event) {
    if (event.preventDefault) {
      event.preventDefault();
    }
    return false;
  }

  dragLeave(event) {
    event.target.classList.remove("over");
    this.resetOpacity();
  }

  drop(event) {
    const party_id = event.target.dataset.partyId;
    console.log("drop")
    event.stopPropagation();
    console.log(event.target.dataset.partyId, "partyId")
    console.log(this.dragSrcEl.dataset.userId, "userId")
    event.target.classList.remove("over");
    this.resetOpacity();
    put(`/parties/${party_id}`, {
      body: JSON.stringify({ user_id: this.dragSrcEl.dataset.userId }),
      contentType: "application/json",
      responseKind: "turbo-stream"
    })

    // fetch("/note_links", {
    //   method: "POST",
    //   credentials: "same-origin",
    //   headers: {
    //     Accept: "text/vnd.turbo-stream.html",
    //     "Content-Type": "application/json",
    //     "X-CSRF-Token": document.head.querySelector("[name='csrf-token']")
    //       .content,
    //   },
    //   body: JSON.stringify({
    //     source_id: this.dragSrcEl.dataset.id,
    //     target_id: event.target.dataset.id,
    //   }),
    // })
    //   .then((response) => response.text())
    //   .then(Turbo.renderStreamMessage);
  }

  dragEnd() {
    this.resetOpacity();
  }

  resetOpacity() {
    this.memberTargets.forEach((el) => {
      el.style.opacity = "1";
    });
  }

  connect(){

  }
}
