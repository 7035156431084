import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { endTime: Number }

  connect() {
    this.startTimer();
  }

  startTimer() {
    const endTime = this.endTimeValue;

    this.timer = setInterval(() => {
        const now = Date.now();
        const remaining = endTime - now;

        if (remaining <= 0) {
            clearInterval(this.timer);
            this.element.textContent = "Subasta Finalizada";
        } else {
            const seconds = Math.floor((remaining % (1000 * 60)) / 1000);
            const minutes = Math.floor((remaining % (1000 * 60 * 60)) / (1000 * 60));
            const totalHours = Math.floor(remaining / (1000 * 60 * 60));

            this.element.textContent = `${String(totalHours).padStart(2, '0')} : ${String(minutes).padStart(2, '0')} : ${String(seconds).padStart(2, '0')}`;
        }
    }, 1000);
  }

  set endTimeValue(value) {
    this.endTime = value;
    this.startTimer();
  }
}
