import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "content", "form"];

  connect() {
    console.log("Modal Open");
  }

  open(event) {
    event.preventDefault();
    const userId = event.currentTarget.getAttribute("data-id");

    this.editUser(userId);

    this.modalTarget.classList.remove("hidden");
    this.modalTarget.classList.add("flex");
  }

  close(event) {
    console.log(this.formTarget);  // This will log the form element or `null` if not found
    const form = this.formTarget;
  
    if (form) {
      form.submit();
    } else {
      console.error("Form target not found!");
    }
  }

  editUser(userId) {
    fetch(`users/${userId}/edit`, {
      headers: {
        Accept: "text/html",
      },
    })
    .then((response) => response.text())
    .then((html) => {

      this.contentTarget.innerHTML = html;

      this.modalTarget.classList.remove("hidden");
    })
    .catch((error) => {
      console.error("Error loading form:", error);
    });
  }

  submitForm(event) {
    console.log("Form submitted!");
    event.preventDefault();  // Prevent the default form submission
  
    const form = this.formTarget;
  
    if (form) {
      form.submit();  // Submit the form after custom logic
      console.log("Form submitted!");
    } else {
      console.error("Form target not found!");
    }
  }
}
